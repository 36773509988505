import { sendRequest } from "../utility/utility";
import { getItem } from "../utility/localStorageControl";


const registerAttendanceUser = async (hashQr, id) => {
    const token = getItem("token");
    const route = `events/check-in/${hashQr}?event_id=${id}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getAssistanceList = async (id, page) => {
    const token = getItem("token");
    const route = `event/attendance/${id}?page=${page}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getAttendanceExcel = async (id) => {
    const token = getItem("token");
    const route = `event/attendance/excel/${id}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request, true);
}

const getSubeventsList = async (id, page) => {
    const token = getItem("token");
    const route = `event/${id}/subevents?page=${page}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getAssistanceListBySubevent = async (id, page) => {
    const token = getItem("token");
    const route = `event/attendance/subevent/${id}?page=${page}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request);
}

const getAttendanceExcelBySubevent = async (id) => {
    const token = getItem("token");
    const route = `subevent/attendance/excel/${id}`;
    const request = {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
    };
    return await sendRequest(route, request, true);
}

export {
    registerAttendanceUser,
    getAssistanceList,
    getAttendanceExcel,
    getSubeventsList,
    getAssistanceListBySubevent,
    getAttendanceExcelBySubevent
}